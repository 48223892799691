<script setup>
import UiBtn from "@/plugins/ui/components/button/UiBtn.vue";

import UiModalPage from "@/components/ui/UiModalPage.vue";
import UiModalPageHeader from "@/components/ui/UiModalPageHeader.vue";
import UiModalPageHeaderClose from "@/components/ui/UiModalPageHeaderClose.vue";
import UiTextField from "@/components/ui/UiTextField.vue";

import { ref, watch } from "vue";
import { useAsync } from "@/use/useAsync";
import { useScore } from "@/use/useScore";
import { getTgInitData } from "@/api/interceptors";

const { fetchRefill } = useScore();

let showModal = ref(false);
let total = ref(0);
let disable = ref(false);
let tg_data = ref(null);

watch(
  () => total.value,
  (nV) => {
    disable.value = Number(nV) <= 0;
  },
  { immediate: true }
);

const handleRefill = useAsync(async () => {
  let res = null;
  tg_data.value = await getTgInitData();

  res = await fetchRefill(total.value * 100);

  if (tg_data.value) {
    window.Telegram.WebApp.openLink(res.qr);
  } else {
    window.location.href = res.qr;
  }
  showModal.value = false;
});
</script>

<template>
  <UiBtn @click="showModal = true" variant="commerce">
    СБП (0,4%)
    <template #left>
      <div class="SbpQr__logo">
        <img src="@/assets/images/sbp-logo.svg" />
      </div>
    </template>
  </UiBtn>

  <UiModalPage v-model="showModal">
    <template #header>
      <UiModalPageHeader>
        <template #before>
          <UiModalPageHeaderClose @click="showModal = false" />
        </template>

        Пополнение баланса
      </UiModalPageHeader>
    </template>

    <template #default>
      <form @submit.prevent="handleRefill.execute()">
        <UiTextField
          v-model="total"
          type="number"
          step="0.01"
          placeholder="0.00"
          label="Сумма"
        />

        <UiBtn
          :loading="handleRefill.isPending.value"
          :disabled="handleRefill.isPending.value || disable"
          block
          class="mt-4"
        >
          Пополнить
        </UiBtn>
      </form>
    </template>
  </UiModalPage>
</template>
<style>
.SbpQr__logo {
  width: 24px;
  height: 24px;
  padding: 1px;

  background-color: white;

  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.SbpQr__logo img {
  height: 100%;
}
</style>
